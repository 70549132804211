import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table } from 'reactstrap';
import { useOktaAuth } from '@okta/okta-react';
import { fetchApis } from '../../store/api/ApiSlice';
import { API_DOMAIN } from '../../globals';
import useMessage from '../../hooks/useMessage';
import ComponentWrapper from '../../components/ComponentWrapper';

const REQUESTS_PAUSER_URL = `${API_DOMAIN}/vision-api/requests-pauser`;

const ApisList = () => {
  const [fetchingDone, setFetchingDone] = useState(false);
  const [pauseModal, setPauseModal] = useState(false);
  const [resumeModal, setResumeModal] = useState(false);
  const [selectedApi, setSelectedApi] = useState('');
  const [pausing, setPausing] = useState(false);
  const [resuming, setResuming] = useState(false);
  const [Message, showMessage] = useMessage();
  const { authState } = useOktaAuth();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchApis(authState.idToken.idToken));
    setFetchingDone(true);
  }, [dispatch]);

  const apis = useSelector((state) => state.api.list);
  console.log(fetchingDone);

  const handleResumeBtn = (apiName) => {
    setSelectedApi(apiName);
    setResumeModal(true);
  };
  const handlePauseBtn = (apiName) => {
    setSelectedApi(apiName);
    setPauseModal(true);
  };

  const handlePauseModalYesBtn = async () => {
    setPausing(true);
    try {
      await axios.post(
        REQUESTS_PAUSER_URL,
        {
          apiName: selectedApi,
          paused: true,
        },
        {
          headers: { Authorization: `Bearer ${authState.idToken.idToken}` },
        },
      );
      await dispatch(fetchApis(authState.idToken.idToken));
      showMessage('API paused successfully :)', 'success');
    } catch (err) {
      showMessage(
        'Something went wrong, please try it later or contact with the administrator.',
        'danger',
      );
    }
    setPausing(false);
    setPauseModal(false);
  };

  const handleResumeModalYesBtn = async (callStoredRequests) => {
    setResuming(true);
    try {
      await axios.post(
        REQUESTS_PAUSER_URL,
        {
          apiName: selectedApi,
          paused: false,
          callStoredRequests,
        },
        {
          headers: { Authorization: `Bearer ${authState.idToken.idToken}` },
        },
      );
      await dispatch(fetchApis(authState.idToken.idToken));
      showMessage('API resuming started successfully :)', 'success');
    } catch (err) {
      showMessage(
        'Something went wrong, please try it later or contact with the administrator.',
        'danger',
      );
    }
    setResuming(false);
    setResumeModal(false);
  };

  return (
    <>
      <Message />
      <ComponentWrapper title="API's">
        <div>
          <div className="table-responsive">
            <Table className="text-nowrap align-middle" borderless>
              <thead>
                <tr>
                  <th>API Name</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {apis.map((api) => (
                  <tr key={api.name} className="border-top">
                    <td>{api.name}</td>
                    <td>
                      {api.callingRequests
                        ? 'Calling stored requests...'
                        : api.paused
                        ? 'Paused'
                        : 'Active'}
                    </td>
                    {api.callingRequests ? (
                      <td></td>
                    ) : (
                      <td>
                        <Button
                          onClick={
                            api.paused
                              ? () => handleResumeBtn(api.name)
                              : () => handlePauseBtn(api.name)
                          }
                          className="btn"
                          color={api.paused ? 'primary' : 'danger'}
                        >
                          {api.paused ? 'Resume' : 'Pause'}
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </ComponentWrapper>
      <Modal isOpen={pauseModal} fade={false} toggle={() => !pausing && setPauseModal(!pauseModal)}>
        <ModalHeader toggle={() => setPauseModal(!pauseModal)}>Pause API</ModalHeader>
        <ModalBody>
          You are going to pause <b>{selectedApi}</b>, are you sure?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handlePauseModalYesBtn(true)} disabled={pausing}>
            Yes
            {pausing && <Spinner className="me-2" color="secondary" size="sm" />}
          </Button>
          <Button color="secondary" onClick={() => setPauseModal(false)} disabled={pausing}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={resumeModal}
        fade={false}
        toggle={() => !resuming && setResumeModal(!resumeModal)}
      >
        <ModalHeader toggle={() => setResumeModal(!resumeModal)}>Resume API</ModalHeader>
        <ModalBody>
          You are going to resume <b>{selectedApi}</b>, are you sure?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleResumeModalYesBtn(true)} disabled={resuming}>
            Yes and also resume stored requests
            {resuming && <Spinner className="me-2" color="secondary" size="sm" />}
          </Button>
          <Button
            color="primary"
            onClick={() => handleResumeModalYesBtn(false)}
            disabled={resuming}
          >
            Yes (stored requests will be lost)
          </Button>
          <Button color="secondary" onClick={() => setResumeModal(false)} disabled={resuming}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ApisList;
