import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import ComponentWrapper from '../../components/ComponentWrapper';
import { API_DOMAIN } from '../../globals';
import styles from './omni-emails.module.css';
import EmailsTable from './OmniEmailsTable';
import LinkAlertBar from '../../components/LinkAlertBar/LinkAlertBar';

const GET_EMAILS_URL = `${API_DOMAIN}/vision-api/get-omni-emails`;
const GET_EMAIL_URL = `${API_DOMAIN}/vision-api/get-omni-email-by-id`;

const OmniEmailsList = () => {
  const [loading, setLoading] = useState(true);
  const [emailLoading, setEmailLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState();
  const [currentEmail, setCurrentEmail] = useState('');
  const { authState } = useOktaAuth();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState();

  const bookingId = searchParams.get('confirmationnumber');
  const productCode = searchParams.get('propertycode');

  if (!bookingId) throw new Error('confirmationnumber not found.');
  if (!productCode) throw new Error('productcode not found.');

  const getEmails = async () => {
    try {
      const { data } = await axios.get(`${GET_EMAILS_URL}`, {
        headers: { Authorization: `Bearer ${authState.idToken.idToken}` },
        params: { bookingId, productCode },
      });

      setEmails(data);
    } catch (e) {
      const denied = e.response?.status === 401;
      setError(denied ? e.response.data : 'Something went wrong. Please try again, or contact us.');
    } finally {
      setLoading(false);
    }
  };

  const handleClickEmail = async (emailId) => {
    setEmailLoading(true);
    setIsModalOpen(true);
    try {
      const { data } = await axios.get(`${GET_EMAIL_URL}?emailId=${emailId}`, {
        headers: { Authorization: `Bearer ${authState.idToken.idToken}` },
      });

      // remove wrapped html tags if they exist
      const body = data.htmlBody.replace(/<html>/, '').replace(/<\/html>/, '');
      setCurrentEmail(body);
    } catch (e) {
      // set the error in the modal
      setCurrentEmail('Something went wrong. Please try again, or contact us.');
    } finally {
      setEmailLoading(false);
    }
  };

  useEffect(() => {
    getEmails();
  }, []);

  if (loading || error) {
    return (
      <div className={styles.loading_container}>
        {loading ? <Spinner color="primary" /> : <p>{error}</p>}
      </div>
    );
  }
  return (
    <>
      <ComponentWrapper
        title={
          <div className="d-flex justify-content-between">
            <span>Omni Emails</span>
          </div>
        }
      >
        {emails.length ? (
          <EmailsTable emails={emails} onClickEmail={handleClickEmail} />
        ) : (
          'No emails found.'
        )}
      </ComponentWrapper>

      {/* modal for viewing email */}
      <Modal isOpen={isModalOpen} fade={false} toggle={() => setIsModalOpen(false)} size="lg">
        <ModalBody>{emailLoading ? 'Loading...' : parse(currentEmail)}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <LinkAlertBar
        content="If you wish to understand how to use the Email screen or seek support,"
        linkContent="please read the SOP at this link"
        linkTo="https://belmond-my.sharepoint.com/:w:/p/erin_slayton/EZbPL9OZbfFBt8NWDLsR59gBlTzOrA-1bGGiQu0sPXJySw"
      />
    </>
  );
};

export default OmniEmailsList;
