import { combineReducers } from 'redux';
import columnsVisibilityReducer from './columnsVisibility/columnsVisibilitySlice';
// Import other reducers as needed

const rootReducer = combineReducers({
  columnsVisibility: columnsVisibilityReducer,
  // Add other reducers here
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
