import MaterialIcon from '@material/react-material-icon';
import { useState } from 'react';
import * as Icon from 'react-feather';
import { NavItem } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Details from './project-detail-screens/Details';
import Items from '../items/Items';
import Contacts from '../contacts/Contacts';
import Addresses from '../addresses/Addresses';
import Rfqs from '../rfqs/Rfqs';
import TooltipButton from '../components/TooltipButton';
import BudgetView from '../budget/BudgetView';

const ProjectView = () => {
  const [activeScreen, setActiveScreen] = useState('details');
  const [showText, setShowText] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    state: { project },
  } = location;

  const nav = [
    { href: 'details', title: 'Details', id: 1, icon: <MaterialIcon icon="inbox" /> },
    { href: 'items', title: 'Items', id: 3, icon: <MaterialIcon icon="inventory" /> },
    { href: 'rfqs', title: 'RFQs', id: 4, icon: <MaterialIcon icon="feed" /> },
    { href: 'contacts', title: 'Contacts', id: 5, icon: <MaterialIcon icon="contacts" /> },
    { href: 'addresses', title: 'Addresses', id: 6, icon: <MaterialIcon icon="business" /> },
    { href: 'budget', title: 'Budget', id: 2, icon: <MaterialIcon icon="paid" /> },
  ];

  const renderContent = () => {
    switch (activeScreen) {
      case 'details':
        return <Details />;
      case 'budget':
        return <BudgetView />;
      case 'items':
        return <Items />;
      case 'rfqs':
        return <Rfqs />;
      case 'contacts':
        return <Contacts />;
      case 'addresses':
        return <Addresses />;
      default:
        return <Details />;
    }
  };

  const toggleTextVisibility = () => {
    setShowText(!showText);
  };

  return (
    <div className="h-100 d-flex flex-column backgroundColor">
      <div className="d-flex justify-content-between align-items-center p-2 my-3">
        <h3>{project.name}</h3>
        <div>
          <TooltipButton
            onClick={() => navigate(-1)}
            id="homeButton"
            tooltipContent="Back to Projects Table"
          >
            <MaterialIcon icon="home" />
          </TooltipButton>
        </div>
      </div>
      <div className="d-flex h-100">
        <div
          style={{
            flex: showText ? 1 : 0.15,
            borderRight: '1px solid lightgray',
            padding: '0 8px',
            height: '100%',
            transition: 'flex 0.3s ease',
          }}
        >
          <TooltipButton
            id="toggleMainMenu"
            onClick={toggleTextVisibility}
            tooltipContent={`${showText ? 'Hide Menu' : 'Show Menu'}`}
            className="my-2"
          >
            <Icon.Menu size={18} />
          </TooltipButton>

          {nav.map((item) => (
            <NavItem
              key={item.id}
              className={`d-flex p-3 align-items-center navItem ${
                activeScreen === item.href ? 'activeNavItem' : ''
              }`}
              onClick={() => setActiveScreen(item.href)}
            >
              <span className="sidebarIcon d-flex align-items-center">{item.icon}</span>
              {showText && (
                <span className="hide-mini w-100">
                  <div className="d-flex align-items-center mx-2">
                    <span>{item.title}</span>
                  </div>
                </span>
              )}
            </NavItem>
          ))}
        </div>

        <div style={{ flex: 6, padding: '0 8px 0 10px', overflow: 'hidden' }}>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default ProjectView;
