const generateCsvContent = (rows, columnHeaders, fileName, renderOnlyVisibleCells) => {
  const rowCells = renderOnlyVisibleCells
    ? (row) => row.getCenterVisibleCells()
    : (row) => row.getAllCells();

  const rowData = rows
    .map((row) =>
      rowCells(row).map((cell) => {
        const cellValue = cell.getValue()?.toString();
        if (cellValue && (cellValue.includes(',') || cellValue.includes('\n'))) {
          const stringWithoutSpace = cellValue.replace(/[\r\n]/gm, ' ');
          return `"${stringWithoutSpace}"`;
        }
        return cellValue;
      }),
    )
    .join('\n');
  const columnNames = columnHeaders.map(({ column }) => column.columnDef.header).join(',');
  const csvContent = `data:text/csv;charset=utf-8,${columnNames}\n${rowData}`;
  const encodedUri = encodeURI(csvContent);

  //create a link and trigger the download
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  //remove link
  document.body.removeChild(link);
};
export default generateCsvContent;
