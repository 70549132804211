import MaterialIcon from '@material/react-material-icon';
import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import { useEffect, useState, type ReactNode, type FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableInstance,
  type MRT_Cell,
} from 'material-react-table';
import generateCsvContent from '../../../helpers/generateCSVData';
import { setVisibleColumns } from '../../../store/columnsVisibility/columnsVisibilitySlice';
import { type RootState } from '../../../store/types';
import { API_DOMAIN } from '../../../globals';

// Types
interface ApiResponse<T> {
  status: string;
  data: T[];
  meta: {
    page: number;
    limit: number;
    totalItems: number;
    totalPages: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
}

interface Address {
  id: string;
  address: {
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
  };
  isPrimary: boolean;
}

interface Region {
  id: string;
  code: string;
  name: string;
}

interface Status {
  id: string;
  code: string;
  name: string;
  color?: string;
}

interface ProjectLead {
  id: string;
  code: string;
  name: string;
  email: string;
}

interface Currency {
  id: string;
  code: string;
  name: string;
  symbol: string;
  exchangeRate: number;
}

interface Project {
  id: string;
  propertyName: string;
  product: string;
  projectName: string;
  description: string;
  reference: string;
  projectValue: number;
  startDate: string;
  completionDate: string;
  workdayProjectReference: string;
  region: Region;
  designStatus: Status;
  financialStatus: Status;
  projectLead: ProjectLead;
  currency: Currency;
  addresses: Address[];
}

interface IconButtonProps {
  icon: string;
  onClick?: (() => void) | undefined;
}

interface TooltipProps {
  content: string;
  children: ReactNode;
  id: string;
}

interface TooltipIconButtonProps extends IconButtonProps {
  id: string;
  tooltipContent: string;
}

interface TableToolbarProps {
  onDownload: () => void;
  onAddNew: () => void;
}

interface DesignStatusCellProps {
  row: MRT_Row<Project>;
}

const DesignStatusCell: FC<DesignStatusCellProps> = ({ row }) => (
  <div
    className="px-2 py-1 rounded"
    style={{
      backgroundColor: row.original.designStatus.color || '#e5e7eb',
      color: row.original.designStatus.color ? 'white' : 'black',
      display: 'inline-block',
    }}
  >
    {row.original.designStatus.name}
  </div>
);

interface DateCellProps {
  cell: MRT_Cell<Project>;
}

const DateCell: FC<DateCellProps> = ({ cell }) => {
  const value = cell.getValue<string>();
  return value ? new Date(value).toLocaleDateString() : '-';
};

interface ProjectValueCellProps {
  row: MRT_Row<Project>;
}

const ProjectValueCell: FC<ProjectValueCellProps> = ({ row }) => {
  const value = row.original.projectValue;
  const { symbol } = row.original.currency;
  return value ? `${symbol}${value.toLocaleString()}` : '-';
};

// Column definitions
const columns: MRT_ColumnDef<Project>[] = [
  {
    accessorKey: 'reference',
    header: 'Reference',
    size: 120,
  },
  {
    accessorKey: 'projectName',
    header: 'Project Name',
    size: 200,
  },
  {
    accessorKey: 'propertyName',
    header: 'Property Name',
    size: 200,
  },
  {
    accessorKey: 'product',
    header: 'Product Type',
    size: 150,
  },
  {
    accessorKey: 'region.name',
    header: 'Region',
    size: 150,
  },
  {
    accessorKey: 'projectLead.name',
    header: 'Project Lead',
    size: 150,
  },
  {
    accessorKey: 'designStatus.name',
    header: 'Design Status',
    Cell: ({ row }: { row: MRT_Row<Project> }) => <DesignStatusCell row={row} />,
    size: 130,
  },
  {
    accessorKey: 'financialStatus.name',
    header: 'Financial Status',
    size: 130,
  },
  {
    accessorKey: 'startDate',
    header: 'Start Date',
    Cell: ({ cell }: { cell: MRT_Cell<Project> }) => <DateCell cell={cell} />,
    size: 120,
  },
  {
    accessorKey: 'completionDate',
    header: 'Completion Date',
    Cell: ({ cell }: { cell: MRT_Cell<Project> }) => <DateCell cell={cell} />,
    size: 120,
  },
  {
    accessorKey: 'projectValue',
    header: 'Project Value',
    Cell: ({ row }: { row: MRT_Row<Project> }) => <ProjectValueCell row={row} />,
    size: 150,
  },
  {
    accessorKey: 'workdayProjectReference',
    header: 'Workday Ref',
    size: 120,
  },
];

const IconButton: FC<IconButtonProps> = ({ icon, onClick }) => (
  <button onClick={onClick} className="icon-button" type="button">
    <MaterialIcon icon={icon} />
  </button>
);

const Tooltip: FC<TooltipProps> = ({ content, children, id }) => (
  <div className="tooltip-wrapper" data-tooltip={content} id={id}>
    {children}
  </div>
);

const TooltipIconButton: FC<TooltipIconButtonProps> = ({ id, icon, tooltipContent, onClick }) => (
  <Tooltip content={tooltipContent} id={id}>
    <IconButton icon={icon} onClick={onClick} />
  </Tooltip>
);

const TableToolbar: FC<TableToolbarProps> = ({ onDownload, onAddNew }) => (
  <div className="flex justify-between items-center p-2 w-full">
    <TooltipIconButton
      id="downloadItems"
      icon="download"
      tooltipContent="Download Projects CSV"
      onClick={onDownload}
    />
    <TooltipIconButton
      id="addNewProject"
      icon="add_circle"
      tooltipContent="Add a new Project"
      onClick={onAddNew}
    />
  </div>
);

const useColumnVisibility = (name: keyof RootState['columnsVisibility']) => {
  const dispatch = useDispatch();
  const visibleColumns = useSelector((state: RootState) => state.columnsVisibility[name]);
  const [columnVisibility, setColumnVisibilityState] = useState(visibleColumns);

  useEffect(() => {
    dispatch(setVisibleColumns({ name, columnVisibility }));
  }, [columnVisibility, dispatch, name]);

  return {
    columnVisibility,
    setColumnVisibility: setColumnVisibilityState,
  };
};

const useProjects = (url: string, authToken: string) => {
  const [data, setData] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchProjects = async () => {
    if (!url || !authToken) {
      setError(new Error('Missing URL or authentication token'));
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const res = await axios.get<ApiResponse<Project>>(url, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      if (res.data.status === 'success') {
        setData(res.data.data);
      } else {
        throw new Error('Failed to fetch projects');
      }
      setError(null);
    } catch (e) {
      console.error('Error fetching projects:', e);
      setError(e instanceof Error ? e : new Error('Unknown error occurred'));
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [url, authToken]);

  return { data, isLoading, error, refetch: fetchProjects };
};

// Main Component
const ProjectsTable: FC = () => {
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const authToken = authState?.idToken?.idToken || '';
  const url = `${API_DOMAIN}/design-studio/projects`;

  const { data: projectsData, isLoading, error, refetch } = useProjects(url, authToken);

  // Memoize the table data to prevent unnecessary re-renders
  const tableData = useMemo(() => projectsData || [], [projectsData]);

  const { columnVisibility, setColumnVisibility } = useColumnVisibility('projects');

  const handleDownload = (table: MRT_TableInstance<Project>) => {
    generateCsvContent(
      table.getPrePaginationRowModel().rows,
      table.getFlatHeaders(),
      'Projects',
      true,
    );
  };

  const handleAddNew = () => {
    navigate('/design-studio/projects/new');
  };

  const handleRowClick = (project: Project) => {
    navigate(`/design-studio/projects/${project.id}`, {
      state: { project },
    });
  };

  if (error) {
    return (
      <div className="error-container p-4">
        <h2 className="text-red-600">Error loading projects</h2>
        <p>{error.message}</p>
        <button
          type="button"
          onClick={() => refetch()}
          className="mt-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="projects">
      <MaterialReactTable
        columns={columns}
        data={tableData}
        enableFilters
        enableColumnFilterModes
        enableColumnOrdering
        onColumnVisibilityChange={setColumnVisibility}
        initialState={{
          density: 'compact',
          showColumnFilters: true,
          sorting: [{ id: 'reference', desc: false }],
        }}
        state={{
          columnVisibility,
          isLoading,
          showSkeletons: isLoading,
          showProgressBars: isLoading,
        }}
        enablePagination={false}
        muiTableContainerProps={{
          sx: { height: '600px' },
        }}
        muiTableBodyCellProps={({ row }) => ({
          onDoubleClick: () => handleRowClick(row.original),
          sx: { cursor: 'pointer' },
        })}
        muiLinearProgressProps={({ isTopToolbar }) => ({
          sx: {
            display: isLoading ? 'block' : 'none',
            position: 'absolute',
            top: isTopToolbar ? 0 : 'auto',
            bottom: isTopToolbar ? 'auto' : 0,
            width: '100%',
          },
        })}
        renderTopToolbarCustomActions={({ table }) => (
          <TableToolbar onDownload={() => handleDownload(table)} onAddNew={handleAddNew} />
        )}
      />
    </div>
  );
};

export default ProjectsTable;
