import PropTypes from 'prop-types';
import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';

const TooltipButton = ({ onClick, className, styles, id, children, tooltipContent }) => {
  return (
    <>
      <UncontrolledTooltip target={id}>{tooltipContent}</UncontrolledTooltip>
      <Button
        color="secondary"
        onClick={onClick}
        id={id}
        className={`d-flex align-items-center ${className}`}
        style={styles}
      >
        {children}
      </Button>
    </>
  );
};

TooltipButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  styles: PropTypes.node,
  id: PropTypes.string,
  children: PropTypes.node,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default TooltipButton;
