import MaterialIcon from '@material/react-material-icon';
import PropTypes from 'prop-types';
import './styles.css';
import { useSearchParams } from 'react-router-dom';

const ComponentWrapper = ({ children, title, subtitle, icon, className }) => {
  const [searchParams] = useSearchParams();
  const embeddedMode = searchParams.get('embedded_mode');

  return (
    <div className="wrapperContainer">
      <div className="wrapperHeader">
        {!embeddedMode && (
          <div className="wrapperTitle">
            {title}
            {icon && <MaterialIcon icon={icon} />}
          </div>
        )}
      </div>
      <div className="wrapperSubtitle">{subtitle}</div>
      <div className={className}>{children}</div>
    </div>
  );
};

ComponentWrapper.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
};

export default ComponentWrapper;
